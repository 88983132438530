<template>
  <component :is="contractData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="contractData === undefined">
      <div class="alert-body">
        Contrato não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-contract-list' }">
          Lista de contratos
        </b-link>
        for other contracts.
      </div>
    </b-alert>

    <template v-if="contractData">
      <div>
        <b-card title="Editar Contrato">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form @submit.prevent="handleSubmit(updateContract())">
              <b-row>
                <b-col md="10">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-group label-for="nameContracts">
                      <template v-slot:label>
                        {{ $t("message.list.contract") }}
                      </template>
                      <b-form-input
                        v-model="contractData.name"
                        class="mt-0"
                        label-for="nameContracts"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="2">
                  <validation-provider
                    #default="{ errors }"
                    name="valor"
                    rules="required"
                  >
                    <b-form-group label-for="descriptionContracts">
                      <template v-slot:label>
                        {{ $t("message.list.value") }}
                      </template>
                      <b-form-input
                        v-money="getCurrencyFormat()"
                        v-model="contractData.value"
                        class="mt-0"
                        label-for="descriptionContracts"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group label-for="descriptionContracts">
                    <template v-slot:label>
                      {{ $t("message.list.description") }}
                    </template>
                    <quill-editor
                      id="title"
                      style="margin-bottom: 52px; height: 200px"
                      :options="editorOption"
                      v-model="contractData.description"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="4">
                  <b-form-group label-for="customerContracts">
                    <template v-slot:label>
                      {{ $t("message.list.customer") }}
                    </template>
                    <v-select
                      v-model="contractData.customerId"
                      :options="customerOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="7" v-if="!contractData.international">
                  <b-form-group label-for="projectContracts">
                    <template v-slot:label>
                      {{ $t("message.list.project") }}
                    </template>
                    <v-select
                      multiple
                      v-model="contractData.projectsId"
                      :options="projectOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="5" v-else>
                  <b-form-group label-for="projectContracts">
                    <template v-slot:label>
                      {{ $t("message.list.project") }}
                    </template>
                    <v-select
                      multiple
                      v-model="contractData.projectsId"
                      :options="projectOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>

                <!-- International -->
                <b-col md="1">
                  <b-form-group label-for="deadlineTasks">
                    <template v-slot:label>
                      {{ $t("message.expenses.international") }}
                    </template>
                    <b-form-checkbox
                      style="margin-left: 25px; margin-top: 10px"
                      v-model="contractData.international"
                      name="is-vertical-menu-collapsed"
                    />
                  </b-form-group>
                </b-col>

                <!-- International Value -->
                <b-col md="2" v-if="contractData.international">
                  <b-form-group label-for="value">
                    <template v-slot:label>
                      {{ $t("message.expenses.price") }}
                    </template>
                    <b-form-input
                      id="value"
                      v-model="contractData.internationalPrice"
                      v-money="getCurrencyFormat()"
                    />
                  </b-form-group>
                </b-col>

                <b-form-group
                  label-for="contractsInstallments"
                  style="margin-top: 20px !important"
                >
                  <div
                    class="form-group"
                    v-for="(input, k) in contractData.installments"
                    :key="k"
                  >
                    <b-row>
                      <b-col md="4" class="action">
                        <b>{{ k + 1 }}ª  </b>
                        {{ $t("message.contracts.parcel") }}
                        {{ $t("message.of") }}
                      </b-col>
                      <b-col md="4">
                        <b-input-group append="%">
                          <b-form-input
                            placeholder="10"
                            v-model="contractData.installmentList[k]"
                          />
                        </b-input-group>
                      </b-col>

                      <b-col md="2">
                        <b-button
                          v-show="k == contractData.installments - 1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="btn-icon"
                          @click="addInstallment(k)"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </b-col>
                      <b-col>
                        <b-button
                          md="2"
                          v-show="k || (!k && contractData.installments > 1)"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="danger"
                          class="btn-icon"
                          @click="removeInstallment(k)"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-form-group>
              </b-row>
            </b-form>
          </validation-observer>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <b-form-group label-for="wildcard">
            <template v-slot:label> {{ $t("uploadFile") }} </template>
            <b-form-file
              style="width: 350px"
              id="wildcard"
              ref="file-input"
              class="mr-1"
              v-model="file"
              browse-text="Procurar"
              placeholder="Nenhum arquivo selecionado"
              drop-placeholder="Arraste arquivo aqui"
              :accept="fileFormats"
            >
            </b-form-file>
            <b-button
              style="margin-top: 7px"
              variant="warning"
              class="btn-icon mr-1"
              @click="file = null"
              v-if="file"
            >
              {{ $t("clean") }}  <feather-icon icon="RefreshCwIcon" />
            </b-button>
            <b-button
              style="margin-top: 7px"
              variant="success"
              class="btn-icon"
              @click="addFile()"
              v-if="file"
            >
              {{ $t("createFile") }}  <feather-icon icon="SendIcon" />
            </b-button>
          </b-form-group>

          <br />

          <b-row>
            <b-col
              style="max-width: 200px; !important"
              v-for="item in filesData"
              :key="item.id"
            >
              <b-card
                text-variant="white"
                class="text-center"
                bg-variant="primary"
              >
                <feather-icon size="40" icon="FileIcon" />

                <div class="truncate">
                  <br />
                  <span>{{ item.name }}</span>
                </div>
                <br />
                <b-row>
                  <b-col md="6"
                    ><b-button
                      size="sm"
                      v-if="restrictions('contract_file_download')"
                      @click="downloadFile(item.id, item.name)"
                      ><feather-icon size="20" icon="DownloadCloudIcon"
                    /></b-button>
                  </b-col>

                  <b-col md="6"
                    ><b-button
                      size="sm"
                      v-b-modal.modal-delete-file-confirmation
                      v-if="restrictions('contract_file_delete')"
                      @click="
                        selectedFileId = item.id;
                        selectedFileName = item.name;
                      "
                      ><feather-icon size="20" icon="XIcon"
                    /></b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0"> </b-card-body>

          <b-modal
            id="modal-delete-contract-confirmation"
            modal-class="modal-danger"
            centered
            :title="$t('delete')"
            hide-footer
          >
            <b-card-text>
              Tem certeza que deseja deletar o registro ?
            </b-card-text>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <b-button
                v-b-modal.modal-danger
                class="mt-2"
                variant="danger"
                :to="{ name: 'apps-contract-list' }"
                @click="deleteContract()"
              >
                {{ $t("message.buttons.delete") }}
              </b-button>
            </div>
          </b-modal>

          <b-modal
            id="modal-delete-file-confirmation"
            modal-class="modal-danger"
            centered
            title="Deletar"
            hide-footer
          >
            <b-card-text>
              Você tem certeza que deseja deletar o arquivo
              <b>{{ selectedFileName }}</b> ?</b-card-text
            >
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <b-button
                v-b-modal.modal-danger
                class="mt-2"
                variant="danger"
                @click="deleteFile()"
              >
                Deletar
              </b-button>
            </div>
          </b-modal>
        </b-card>
      </div>
    </template>
    <div class="text-right">
      <b-button
        variant="primary"
        @click="updateContract()"
        :to="{ name: 'apps-contract-list' }"
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BFormSelect,
  BInputGroup,
  BFormFile,
  BCardBody,
  BCardText,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import Ripple from "vue-ripple-directive";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import download from "downloadjs";
import getHeaderFile from "@core/utils/authFile";
import AccessControl from "@core/utils/access-control";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    BFormTextarea,
    BInputGroup,

    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BFormFile,
    BCardBody,
    BCardText,
  },

  directives: {
    Ripple,
    money: VMoney,
  },

  data() {
    return {
      //Select
      customerOptions: [],
      projectOptions: [],
      userData: store.state.user.userData,

      paramData: store.state.user.paramData,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
    file: function (newVal, oldVal) {
      if (newVal != null) {
        const maxAllowedSize = this.fileMaxSize * 1024 * 1024;
        if (newVal.size > maxAllowedSize) {
          // Here you can ask your users to load correct file
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Não permitido",
              icon: "XIcon",
              variant: "danger",
              text: "Arquivo com tamanho maior do que permitido pelo sistema",
            },
          });
          this.$refs["file-input"].reset();
        }
      }
    },
  },

  async created() {
    var projectList = [];
    var projectId = [];
    await axios
      .get(`${URL_API}project/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          projectList.push(element);
          projectId.push(id);
          this.projectOptions.push({
            label: projectList[i],
            value: projectId[i],
          });
        }
      });

    var customerList = [];
    var customerId = [];
    await axios
      .get(`${URL_API}customer/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          customerList.push(element);
          customerId.push(id);
          this.customerOptions.push({
            label: customerList[i],
            value: customerId[i],
          });
        }
      });

    await this.getContractFilesData();

    this.loadFileFormats();
  },

  methods: {
    async getContractFilesData() {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    restrictions(value) {
      return AccessControl(value);
    },

    loadFileFormats() {
      this.fileFormats = this.paramData.fileTypes.join(", ").toString();
      this.fileMaxSize = this.paramData.maxFileSize;
    },

    addFile() {
      let formData = new FormData();
      formData.append("file", this.file);

      axios
        .post(
          `${URL_API}file/contract/${this.userData.id}/${router.currentRoute.params.id}`,
          formData,
          {
            headers: getHeaderFile(this.userData),
          }
        )
        .then((response) => {
          if (!response.data.id && response.data != "") {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("noDataAvailable"),
                icon: "XIcon",
                variant: "danger",
                text: this.$i18n.t("noDataAvailableDesc"),
              },
            });
          } else if (response.data === "") {
            this.getContractFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          } else {
            this.getContractFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("message.toast.create"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$i18n.t("message.toast.create"),
              },
            });
          }
        });
    },

    deleteFile() {
      axios({
        method: "delete",
        url: `${URL_API}file/${this.selectedFileId}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        if (response.data === "") {
          this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
        } else {
          this.getContractFilesData();
          this.$root.$emit("bv::hide::modal", "modal-delete-file-confirmation");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Deletado com sucesso!",
              icon: "CheckIcon",
              variant: "success",
              text: "Arquivo deletado com sucesso",
            },
          });
        }
      });
    },

    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          } else {
          const content = response.headers["content-type"];
          download(response.data, name, content);
        }
      });
    },

    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    addInstallment() {
      if (this.contractData.installments === undefined) {
        this.contractData.installments = 2;
      } else {
        this.contractData.installments++;
      }
    },

    removeInstallment(index) {
      this.contractData.installments--;
      this.contractData.installmentList.splice(index, 1);
    },

    updateContract() {
      if (this.$i18n.locale === "en") {
        this.contractData.value = Number(
          this.contractData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      } else {
        this.contractData.value = this.switchDotsAndCommas(
          this.contractData.value
        );
        this.contractData.value = Number(
          this.contractData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      }

      axios({
        method: "put",
        url: `${URL_API}contract/${this.contractData.id}`,
        headers: getHeader(this.userData),
        data: this.contractData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },
  },

  setup() {
    const contractData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}contract/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        contractData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          contractData.value = undefined;
        }
      });

    return {
      contractData,
      vSelect,
    };
  },
};
</script>
